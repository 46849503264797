<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="$can('update', 'role-permission')"
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-1"
            :to="{
              name: 'update-role-permission',
              params: { id: $route.params.id },
            }"
          >
            <feather-icon icon="EditIcon" />
            {{ $t("button.update") }}
          </b-button>
        </b-col>
      </b-row>
      <n-input-view :fields="fields" v-model="data" :initValue="initData">
        <template #abilities>
          <permission-table
            :loading="permissionLoading"
            :items="permissions"
            v-model="data.abilities"
            :readonly="true"
          ></permission-table>
        </template>
      </n-input-view>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mt-1"
            @click="back"
          >
            {{ $t("button.back") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonDelete from "@/components/NButtonDelete";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInputView from "@/components/NInputView";
import formInput from "./formInput";
import PermissionTable from "./PermissionTable.vue";

const RoleRepository = Repository.get("role");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonDelete,
    NFormConfirmation,
    NInputView,
    PermissionTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        firstName: null,
        lastName: null,
        image: null,
        gender: "male",
        username: null,
        email: null,
        password: null,
      },
      permissions: [],
      initData: {},
      permissionLoading: false,
      loading: false,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      this.permissionLoading = true;
      RoleRepository.show(this.$route.params.id)
        .then((response) => {
          if (response?.data?.data) {
            this.initData = {
              ...response.data.data,
            };
            this.permissions = [...this.initData.abilities];
          }
        })
        .catch()
        .then(() => {
          this.permissionLoading = false;
        });
    },
    deleteItem() {
      RoleRepository.delete(this.$route.params.id)
        .then((response) => {
          this.back();
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.back();
    },
  },
  setup() {
    const fields = [...formInput];
    return { fields };
  },
};
</script>